import "./App.css";
/* import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'; */

/* const stripePromise = loadStripe('pk_live_51OouakDI4RlD3Teut080S0xpBcVeso5l4XDT8oBwzLTonrildz21ikeXp8wJYejgt8DDFNd0M4r7nYO2mt2t1Ar100yxCtDscF', {
}); */

function App({ clientSecret }) {
  // const options = {clientSecret};

  return (
    <div className="App">
      {/* <!--Nav--> */}
      <nav id="header" class=" w-full z-30 top-0 text-white py-2">
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
          <div id="logo-mobile" class="flex items-center">
            <a
              class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
              href="/"
            >
              <img src="talqio2.png" alt="Logo Talqio" width="155px" />
            </a>
          </div>
          <div
            class="w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
            id="nav-content"
          >
            <ul class="list-reset lg:flex justify-end flex-1 items-center font-bold">
              <li class="mr-3">
                <a
                  class="inline-block text-white no-underline hover:text-gray-300 hover:text-underline py-2 px-4"
                  href="#home"
                >
                  Home
                </a>
              </li>
              <li class="mr-3">
                <a
                  class="inline-block text-white no-underline hover:text-gray-300 hover:text-underline py-2 px-4"
                  href="#planos"
                  rel="noopener noreferrer"
                >
                  Planos
                </a>
              </li>
              <li class="mr-3">
                <a
                  class="inline-block text-white no-underline hover:text-gray-300 hover:text-underline py-2 px-4"
                  href="#sobre"
                >
                  Sobre nós
                </a>
              </li>
            </ul>
          </div>
          <div
            class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden sm:mt-2 lg:mt-0 bg-white lg:bg-transparent text-black sm:p-4 lg:p-0 z-20"
            id="nav-content"
          >
            <ul class="list-reset lg:flex justify-end flex-1 items-center font-bold">
              <li class="mr-3">
                <a 
                  id="fale-mobile"
                  class="inline-block text-white no-underline hover:text-gray-300 hover:text-underline py-2 px-4"
                  href="#contatar"
                >
                  Fale conosco
                </a>
              </li>
            </ul>
            <a
              id="fale-mobile"
              href="#planos"
              class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              Comece agora
            </a>
          </div>
          <a
            id="fale-mobile-dois"
            href="#planos"
            class="mx-auto lg:mx-0 hidden hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Comece agora
          </a>
        </div>
        <hr
          id="horizontal-line"
          class="border-b border-gray-100 opacity-25 my-2 py-0"
        />
      </nav>

      {/* <!--Hero--> */}
      <div id="home" class="pt-20">
        <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center flex-1">
          {/* <!--Left Col--> */}
          <div
            id="left-col"
            class="flex flex-col w-1/2 justify-center items-start text-center md:text-left"
          >
            {/* <p class="uppercase tracking-loose w-full">a helpdesk platform using whatsapp features</p> */}
            <h1 class="my-4 text-5xl font-bold leading-tight">
              Transformamos sua equipe de atendimento com ferramentas que
              proporcionam super poderes!
            </h1>
            <p class="leading-normal text-lg mb-8">
              Unificamos o atendimento ao cliente da sua empresa em uma
              plataforma organizada, fácil de usar e que reúne toda a sua equipe
              sob um único número de WhatsApp.
            </p>
            <a
              href="#planos"
              class="relative lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer z-50 mb-20"
            >
              Comece agora
            </a>
          </div>

          {/* <!--Right Col--> */}
          <div class="relative w-1/2 py-6 text-center mt-1 mb-10 md:w-100">
            <img
              id="home-hero"
              class="relative z-10 w-full md:w-4/5 mb-10 ml-24"
              alt="hero"
              src="homepg.png"
            />
          </div>
        </div>
      </div>

      {/* Separator 1 */}
      <div class="relative -mt-12 lg:-mt-24">
        <img src="separator.svg" alt="" />
      </div>

      {/* Who Talqio */}
      <section id="sobre" class="bg-white border-b py-8">
        <div class="container mx-auto flex flex-wrap pt-4">
          <h2 class="w-full my-2 text-4xl sm:text-5xl font-bold leading-tight text-center text-gray-800">
            Para quem é o Talqio?
          </h2>
          <div class="w-full mb-4">
            <div class="h-1 mx-auto gradient w-64 opacity-25 mb-3 my-0 py-0 rounded-t"></div>
          </div>
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  🏢 &nbsp;
                  <p class="font-bold">Escritórios</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Escritórios de contabilidade e advocacia
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left">
                  Assuma o controle do seu escritório de contabilidade. Veja
                  solicitações não tratadas, demandas sem acompanhamento,
                  impostos atrasados, prazos perdidos, tudo isso com uma
                  ferramenta de atendimento ao cliente profissional em sua
                  empresa.
                </p>
              </a>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-lg rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  🏭 &nbsp;
                  <p class="font-bold">Indústria alimentícia</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Padarias, confeitarias, restaurantes e delivery
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left mb-8">
                  Separe seus setores de atendimento ao cliente, entrega e
                  suporte ao cliente para maximizar a eficiência da sua
                  operação. Além disso, aproveite o histórico dos clientes para
                  promoções, oportunidades e até mesmo uma mensagem de "há
                  quanto tempo não nos falamos" para os clientes que não entram
                  em contato há algum tempo.
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="container mx-auto flex flex-wrap">
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  🏬 &nbsp;
                  <p class="font-bold">Varejo</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Lojas, vendas e e-commerce
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left">
                  Estabeleça uma operação de vendas com todos os departamentos
                  responsáveis pelos pedidos para lidar eficientemente com a
                  demanda, especialmente durante períodos de pico, como
                  campanhas de aniversário, Dia das Mães e Black Friday. Utilize
                  canais para aumentar as vendas e oferecer suporte ao cliente
                  em casos que necessitem de contato rápido.
                </p>
              </a>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  🏥 &nbsp;
                  <p class="font-bold">Saúde</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Hospitais e farmácias de manipulação
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left mb-8">
                  Torne o atendimento da sua farmácia ainda mais personalizado.
                  Imagine fazer a venda de um medicamento e depois perguntar ao
                  cliente como ele está se sentindo? Isso promove uma relação
                  saudável, e utilizar o histórico deles pode ser ótimo para
                  clientes que usam medicamentos controlados e podem precisar
                  fazer uma nova compra.
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="container mx-auto flex flex-wrap pb-12">
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  📚 &nbsp;
                  <p class="font-bold">Educação</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Escolas, faculdades e cursos
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left">
                  Implemente uma abordagem de atendimento ao cliente em sua
                  instituição educacional que priorize alunos, pais e
                  prestadores de serviço. Utilize um chatbot para direcionar
                  eficientemente aos diferentes departamentos (suporte
                  educacional, coordenação, financeiro) e aproveite o
                  agendamento para lembrar alunos/pais sobre tarefas ou até
                  mesmo fornecer informações sobre passeios, vestibulares e
                  compra de materiais.
                </p>
              </a>
            </div>
          </div>
          <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a
                href="/"
                class="flex flex-wrap no-underline hover:no-underline"
              >
                <div class="w-full pt-3 text-xl text-gray-800 px-6 flex flex-row space-x-3">
                  🏠 &nbsp;
                  <p class="font-bold">Imobiliário</p>
                </div>
                <p class="w-full px-6 text-gray-600 text-sm flex mt-3 mb-2">
                  Imobiliárias e corretoras
                </p>
                <p class="text-gray-800 text-base mb-6 px-6 flex text-left mb-8">
                  Facilite o contato com potenciais compradores ou locatários
                  por meio de uma ferramenta que organiza e auxilia sua equipe
                  de corretores a tornar o sonho da compra uma realidade.
                  Utilize filtros para dividir em diferentes equipes ou até
                  mesmo oportunidades em diferentes bairros. Use a ferramenta de
                  agendamento de mensagens para entrar em contato com os
                  clientes quando estiverem disponíveis e mantenha-se em contato
                  com sua equipe durante as negociações para agilizar o processo
                  de aquisição.
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Why Talqio */}
      <section class="bg-gray-100 py-8">
        <div class="container mx-auto px-2 pt-10 pb-12 text-gray-800">
          <h2 class="w-full my-2 text-4xl sm:text-5xl font-bold leading-tight text-center text-gray-800">
            Por que o Talqio é a melhor solução?
          </h2>
          <div class="w-full mb-16">
            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
            <div class="flex flex-col w-5/6 lg:w-1/2 mx-auto lg:mx-0 border-8 border-blue-600 rounded-lg bg-white sm:-mt-6 shadow-lg z-10">
              <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div class="w-full p-8 text-4xl font-bold flex justify-center text-blue-600">
                  {/* <img src="logo-roxo.png" alt="Logo Bedesk" width="150px" /> */}
                  Talqio
                </div>
                <hr />
                <ul class="w-full text-center text-base">
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Atendentes Ilimitados
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Suporte 24 Horas
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Kanban para gerenciamento de tarefas
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Chatbot e Chatbot IA
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Agendamentos personalizados
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Respostas rápidas personalizadas
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Campanhas & pesquisas de satisfação
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    Integrações, API & Webhooks
                  </li>
                  {/* <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="check.svg" alt="check" class="mr-10" />
                    More closed deals and satisfied customers with your business
                  </li> */}
                </ul>
              </div>
            </div>
            <div class="mr-10"></div>
            <div class="flex flex-col w-5/6 lg:w-1/2 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
              <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div class="w-full p-8 text-2xl sm:text-3xl mb-2 font-bold text-center">
                  WhatsApp Business
                </div>
                <hr />
                <ul class="w-full text-center text-base">
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Usuário único por conta
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Falta de suporte dedicado
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Sem gerenciamento de tarefas
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Automação limitada
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Sem agendamento integrado
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Respostas automáticas limitadas
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Sem recursos de marketing
                  </li>
                  <li class="border-b text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Integração limitada
                  </li>
                  {/* <li class="text-left flex py-4 pl-8 pr-12">
                    <img src="uncheck.svg" alt="check" class="mr-10" />
                    Loss of money and customers
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="have-you" class="flex px-20 pt-32 mx-10 gradient rounded-lg">
          <div class="w-1/2">
            <img src="image-lead.png" alt="lead img" width="400px" />
          </div>
          <div class="w-1/2">
            <h2 class="text-white text-4xl font-bold align-center">
              Você já considerou algum setor de negócios ou empresa?
            </h2>
            <p class="text-3xl mt-10 mb-16">
              O Talqio é a escolha certa para isso, comece sua assinatura hoje
              mesmo.
            </p>
            <a
              href="#planos"
              class="mx-auto lg:mx-0 mt-16 hover:underline bg-white text-blue-600 text-xl font-bold rounded-md my-6 py-2 px-10 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              Escolha seu plano agora
            </a>
          </div>
        </div>
      </section>

      {/* Pricing */}
      <section class="bg-white py-16">
        <div class="container mx-auto px-2 pt-10 pb-12 text-gray-800">
          <h2
            id="planos"
            class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800"
          >
            Planos
          </h2>
          <div class="w-full mb-4">
            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div class="justify-center pt-5 my-12 grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* <!-- Plano básico --> */}
            <div class="bg-white shadow-lg rounded-lg p-6 flex flex-col border-t-8 border-purple-100">
              <h2 class="text-2xl font-bold mb-4">Básico</h2>
              <p class="text-3xl font-bold mb-4">
                R$149<small class="font-normal">/mês</small>
              </p>
              <p class="mb-4 text-lg">Até 3 usuários</p>
              <ul id="list-plans" class="text-left text-lg mb-12">
                <li>1 conexão</li>
                <li>Suporte 24 Horas</li>
                <li>Kanban</li>
                <li>Chatbot</li>
                <li>Agendamentos</li>
                <li>Respostas rápidas</li>
                <li>Campanhas</li>
                <li>Integrações</li>
                <li>API & Webhooks</li>
              </ul>
              <div class="mb-6">
                <a
                  class="text-purple-500 hover:text-white py-5 px-16 rounded-full font-semibold border-2 border-purple-300 hover:bg-purple-300 transition duration-300 ease-in-out transform hover:scale-105"
                  href="https://super.talqio.app/signup"
                >
                  Contratar
                </a>
              </div>
            </div>

            {/* <!-- Plano profissional --> */}
            <div class="bg-white shadow-lg rounded-lg p-6 flex flex-col border-t-8 border-blue-100 rgb-border">
              <h2 class="flex text-2xl font-bold mb-4 justify-center">
                Profissional &nbsp;
                <span class="font-bold text-sm pt-1.5 text-white inline-block align-middle px-4 rounded-2xl bg-gradient-to-r from-green-400 to-blue-500">
                  POPULAR
                </span>
              </h2>
              <p class="text-3xl font-bold mb-4">
                R$199<small class="font-normal">/mês</small>
              </p>
              <p class="mb-4 text-lg">Até 5 usuários</p>
              <ul id="list-plans" class="text-left text-lg mb-12">
                <li>1 conexão</li>
                <li>Suporte 24 Horas</li>
                <li>Kanban</li>
                <li>Chatbot</li>
                <li>Chatbot IA</li>
                <li>Agendamentos</li>
                <li>Respostas rápidas</li>
                <li>Campanhas</li>
                <li>Integrações</li>
                <li>API & Webhooks</li>
              </ul>
              <div class="mb-6">
                <a
                  class="text-blue-500 hover:text-white py-5 px-16 rounded-full font-semibold border-2 border-blue-300 hover:bg-blue-300 transition duration-300 ease-in-out transform hover:scale-105"
                  href="https://super.talqio.app/signup"
                >
                  Contratar
                </a>
              </div>
            </div>

            {/* <!-- Plano empresarial --> */}
            <div class="bg-white shadow-lg rounded-lg p-6 flex flex-col border-t-8 border-pink-100">
              <h2 class="text-2xl font-bold mb-4">Empresarial</h2>
              <p class="text-3xl font-bold mb-4">
                R$249<small class="font-normal">/mês</small>
              </p>
              <p class="mb-4 text-lg">Até 10 usuários</p>
              <ul id="list-plans" class="text-left text-lg mb-12">
                <li>1 conexão</li>
                <li>Suporte 24 Horas</li>
                <li>Kanban</li>
                <li>Chatbot</li>
                <li>Chatbot IA</li>
                <li>Agendamentos</li>
                <li>Respostas rápidas</li>
                <li>Campanhas</li>
                <li>Integrações</li>
                <li>Chat interno</li>
                <li>API & Webhooks</li>
              </ul>
              <div class="mb-6">
                <a
                  class="text-pink-500 hover:text-white py-5 px-16 rounded-full font-semibold border-2 border-pink-300 hover:bg-pink-300 transition duration-300 ease-in-out transform hover:scale-105"
                  href="https://super.talqio.app/signup"
                >
                  Contratar
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Plano exclusivo --> */}
          <div class="justify-center pt-0 my-12 grid grid-cols-1">
            <div
              id="plans-exclusive"
              class="flex flex-col md:flex-row justify-between bg-white shadow-lg rounded-lg p-10"
            >
              <div class="mb-6 md:mb-0 content-center">
                <h2 class="text-2xl text-left font-bold mb-4">Exclusivo 🌟</h2>
                <p class="mb-4 text-lg text-left">
                  Solução exclusiva com 50 usuários
                </p>
                <p class="text-2xl text-left font-bold mb-4">
                  R$999<small class="font-normal">/mês</small>
                </p>
              </div>
              <div class="mb-6 md:mb-0">
                <ul id="list-plans" class="text-left text-lg">
                  <li>5 conexões</li>
                  <li>Tudo do Empresarial</li>
                  <li>Suporte prioritário 24 Horas</li>
                  <li>Consultoria no treinamento do Chatbot IA</li>
                </ul>
              </div>
              <div class="content-center mt-6 sm:mt-0">
                <a
                  class="text-green-500 hover:text-white py-5 px-16 rounded-full font-semibold border-2 border-green-300 hover:bg-green-400 transition duration-300 ease-in-out transform hover:scale-105"
                  href="https://super.talqio.app/signup"
                >
                  Contratar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Change the colour #f8fafc to match the previous section colour */}
      <img src="wave.svg" alt="" />

      {/* Doubts */}
      <section class="container mx-auto text-center py-12 mb-12">
        <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          Você ainda tem dúvidas?
        </h2>
        <div class="w-full mb-4">
          <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <h3 class="my-4 text-2xl sm:text-3xl leading-tight">
          Ainda não tem certeza se o Talqio é a solução certa para você?
        </h3>{" "}
        <br />
        <a
          href="/"
          class="mx-auto lg:mx-0 hover:underline bg-white text-blue-600 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
        >
          Fale com um especialista
        </a>
      </section>

      {/* <!--Footer--> */}
      <footer id="contatar" class="gradient bg-white">
        <div class="container mx-auto px-8">
          <div class="w-full flex flex-col md:flex-row py-6">
            <div class="flex-1 mb-6 text-black">
              <a
                class="text-pink-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <img src="talqio2.png" alt="" width="150px" />
              </a>
            </div>
            {/* <div class="flex-1">
              <p class="uppercase text-gray-100 md:mb-6">Links</p>
              <ul class="list-reset mb-6">
                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href="/"
                    class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    Help
                  </a>
                </li>
                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href="/"
                    class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <div class="flex-1">
              <p class="uppercase text-gray-500 md:mb-6">Legal</p>
              <ul class="list-reset mb-6">
                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href="/"
                    class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    Terms
                  </a>
                </li>
                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href="/"
                    class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    Privacy
                  </a>
                </li>
              </ul>
            </div> */}
            <div class="flex flex-row">
              <ul class="list-reset flex mb-6 text-sm sm:text-base">
                {/* <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                    <a href="/" class="no-underline hover:underline text-gray-800 hover:text-pink-500">Facebook</a>
                  </li> */}
                <li class="mt-2 mr-2">
                  <a href="https://www.linkedin.com/company/talqio" class="no-underline hover:underline font-semibold text-gray-100 hover:text-gray-200" target="_blank" rel="noopener noreferrer">
                    Linkedin
                  </a>
                </li>

                <li class="mt-2 mr-2">|</li> {/* Linha sep */}

                <li class="mt-2 mr-2">
                  <a href="https://instagram.com/talqio.app" class="no-underline hover:underline font-semibold text-gray-100 hover:text-gray-200" target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                </li>

                <li class="mt-2 mr-2">|</li> {/* Linha sep */}

                <li class="mt-2 mr-2">
                  <a href="#sobre" class="no-underline hover:underline font-semibold text-gray-100 hover:text-gray-200">
                    Sobre
                  </a>
                </li>

                <li class="mt-2 mr-2">|</li> {/* Linha sep */}

                <li class="mt-2 mr-2">
                  <a href="#contatar" class="no-underline hover:underline font-semibold text-gray-100 hover:text-gray-200">
                    Fale conosco
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex flex-col container mx-auto text-center py-6">
          <a href="https://cyostech.com" class="text-gray-100" target="_blank" rel="noopener noreferrer">
            © 2024 designed and secured by <b>Cyos</b>
          </a>
          {/* <a
            class="inline-block text-gray-700 font-bold underline hover:text-gray-500 hover:text-underline py-2 px-4"
            href="https://billing.stripe.com/p/login/fZe9DL8rl3jQ5BmfYY"
            target="_blank"
            rel="noopener noreferrer"
          >
            Management Plan
          </a> */}
        </div>
      </footer>
    </div>
  );
}

export default App;
